import React, { useEffect, useState, createContext } from "react";
import { loadWeb3, detectNetworkAccount } from "src/plugins/Web3Connection";

export const BlockchainDefaultData = {
	account: null,
	networkId: null,
	connectToBlockchain: () => {},
	disconnectFromBlockchain: () => {},
};

export const BlockchainContext = createContext(BlockchainDefaultData);

const BlockchainProvider = props => {
	const [tryReconnect, setTryReconnect] = useState(0);
	const [blockchainInfo, setBlockchainInfo] = useState(BlockchainDefaultData);
	const [processing, setProcessing] = useState(false);

	const updateBlockchainInfo = () => {
		setProcessing(true);

		try {
			detectNetworkAccount()
				.then(info => {
					console.log(info);
					setBlockchainInfo(info);
				})
				.catch(err => {
					// user disconnected the account
					setBlockchainInfo(BlockchainDefaultData);
				})
				.finally(() => {
					setProcessing(false);
				});
		} catch (e) {
			setProcessing(false);
			console.log(e);
		}
	};

	useEffect(() => {
		if (tryReconnect === 0) return;

		loadWeb3()
			.then(() => {
				if (window.web3) {
					if (window.ethereum) {
						window.ethereum.on("accountsChanged", function (accounts) {
							updateBlockchainInfo();
						});

						window.ethereum.on("chainChanged", function (network) {
							updateBlockchainInfo();
						});
					}

					updateBlockchainInfo();
				}
			})
			.catch(e => {});

		setTryReconnect(0);
	}, [tryReconnect]);

	const connectToBlockchain = () => setTryReconnect(1);

	const disconnectFromBlockchain = () => {
		setBlockchainInfo(BlockchainDefaultData);
	};

	return (
		<BlockchainContext.Provider
			value={{ ...blockchainInfo, processing, connectToBlockchain, disconnectFromBlockchain }}
		>
			{props.children}
		</BlockchainContext.Provider>
	);
};

export default BlockchainProvider;
