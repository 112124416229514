import Web3 from "web3";

//export const vestingContractAddress = "0xCbd06E680b8169116269aA28BB1f8F611dbd2C09";
//export const tokenContractAddress = "0xE57d1E9B045CB91F5F073A897ca1f445a187Ca5c";
export const vestingContractAddress = "0xff84F21428cbcB143788f97913B7b54d667C5c72";
export const tokenContractAddress = "0x4f1e00D9Fd6a85fc6508d76f94f255A510442bE4";

export const nftContractAddress = "0xc90F85aCdCaBaCe79b4FaD10EAc439F5A94C4e77";
export const nftStakingAddress = "0x8C02d5FCF96C8D704b22bc9FeB7773433FdBc71a";
export const nftVestingAddress = "0xD16BcDd341CFf9a9d140eCa364191E2ECf58bFA0";

function getContractCodes() {
	const compiledContract = require("src/contracts/Vesting.json");
	return compiledContract;
}

function getNFTContractCodes() {
	const compiledContract = require("src/contracts/Whiskey721.json");
	return compiledContract;
}

function getNFTStakingCodes() {
	const compiledContract = require("src/contracts/NFTStaking.json");
	return compiledContract;
}

function getNFTVestingCodes() {
	const compiledContract = require("src/contracts/NFTVesting.json");
	return compiledContract;
}

export const getVestingScheduleForWallet = (mode = "") =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const vestingContract = new web3.eth.Contract(
			getContractCodes().abi,
			mode === "nft" ? nftVestingAddress : vestingContractAddress
		);
		const accounts = await web3.eth.getAccounts();

		try {
			const vestingSchedule = await vestingContract.methods
				.getVestingScheduleByAddressAndIndex(accounts[0], 0)
				.call({
					from: accounts[0],
				});

			if (vestingSchedule) {
				const currentTimeInUTC = Math.floor(new Date().getTime() / 1000);

				resolve({
					startTime: parseInt(vestingSchedule[2]),
					endTime: parseInt(vestingSchedule[2]) + parseInt(vestingSchedule[4]),
					totalTokens: parseInt(vestingSchedule[7]),
					alreadyClaimed: parseInt(vestingSchedule[8]),
					nextClaimTime:
						parseInt(vestingSchedule[2]) +
						Math.ceil((currentTimeInUTC - parseInt(vestingSchedule[2])) / parseInt(vestingSchedule[5])) *
							parseInt(vestingSchedule[5]),
				});
			}
		} catch (e) {
			reject(e.message);
		}

		resolve(null);
	});

export const getAvailableToClaimForWallet = (mode = "") =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const vestingContract = new web3.eth.Contract(
			getContractCodes().abi,
			mode === "nft" ? nftVestingAddress : vestingContractAddress
		);
		const accounts = await web3.eth.getAccounts();

		try {
			const vestingScheduleID = await vestingContract.methods
				.computeVestingScheduleIdForAddressAndIndex(accounts[0], 0)
				.call();

			if (vestingScheduleID) {
				const availableToClaim = await vestingContract.methods
					.computeReleasableAmount(vestingScheduleID)
					.call();

				if (availableToClaim) {
					resolve({ availableToClaim, vestingScheduleID });
				}
			}

			resolve(0);
		} catch (e) {
			reject(e.message);
		}

		resolve(0);
	});

export const claimTokens = (vestingScheduleId, amount, mode = "") =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const vestingContract = new web3.eth.Contract(
			getContractCodes().abi,
			mode === "nft" ? nftVestingAddress : vestingContractAddress
		);
		const accounts = await web3.eth.getAccounts();

		try {
			const vestingScheduleID = await vestingContract.methods.release(vestingScheduleId, amount).send({
				from: accounts[0],
			});

			resolve();
		} catch (e) {
			reject(e.message);
		}

		resolve();
	});

export const stakeNFT = tokenID =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const nftContract = new web3.eth.Contract(getNFTStakingCodes().abi, nftStakingAddress);
		const accounts = await web3.eth.getAccounts();

		try {
			// check if already staked
			const staked = await nftContract.methods.isStaked(tokenID).call();
			if (staked) {
				resolve();
				return;
			}

			await nftContract.methods.stakeNFT(tokenID).send({
				from: accounts[0],
			});

			resolve();
		} catch (e) {
			console.log(e, tokenID);
			reject(e.message);
		}

		resolve();
	});

export const getAllStakes = () =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const accounts = await web3.eth.getAccounts();

		const stakes = [];

		try {
			const stakingContract = new web3.eth.Contract(getNFTStakingCodes().abi, nftStakingAddress);
			//get staking index
			let i = 0;
			while (true) {
				const stake = await stakingContract.methods.stakes(accounts[0], i).call();
				if (!stake[1]) break;

				if (!stake.isWithdrawn) stakes.push({ tokenId: stake.tokenId, stakingIndex: i });

				i++;
			}
		} catch (e) {}

		resolve(stakes);
	});

export const createNFTVesting = (tokenID, stakingIndex) =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const vestingContract = new web3.eth.Contract(getNFTVestingCodes().abi, nftVestingAddress);
		const accounts = await web3.eth.getAccounts();

		try {
			const vestedBefore = await vestingContract.methods.tokensVested(tokenID).call();
			if (vestedBefore) {
				reject("You have already staked NFT");
				return;
			}

			if (typeof stakingIndex === "undefined") {
				stakingIndex = 0;
				const allStakesByUser = await getAllStakes();

				allStakesByUser.map(nft => {
					if (nft.tokenId === tokenID) stakingIndex = nft.stakingIndex;
				});
			}

			await vestingContract.methods.createVestingSchedule(stakingIndex).send({
				from: accounts[0],
			});

			resolve();
		} catch (e) {
			reject(e.message);
		}

		resolve();
	});

export const checkAlreadyStaked = tokenID =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const nftContract = new web3.eth.Contract(getNFTStakingCodes().abi, nftStakingAddress);
		const accounts = await web3.eth.getAccounts();

		try {
			// check if already staked
			if (tokenID) {
				const staked = await nftContract.methods.isStaked(tokenID).call();
				if (staked) {
					resolve(true);
				}
			} else {
				const staked = await nftContract.methods.stakes(accounts[0], 0).call();
				if (staked) {
					resolve(true);
				}
			}
		} catch (e) {
			resolve(false);
		}

		resolve(false);
	});

export const checkIfNFTAvailableToWithdraw = () =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const nftContract = new web3.eth.Contract(getNFTStakingCodes().abi, nftStakingAddress);
		const accounts = await web3.eth.getAccounts();

		try {
			const staked = await nftContract.methods.stakes(accounts[0], 0).call();
			if (staked) {
				const now = Math.floor(new Date().getTime() / 1000);
				if (!staked.isWithdrawn && staked.startTime > 0 && staked.endTime < now) {
					resolve(true);
				}
			}
		} catch (e) {
			resolve(false);
		}

		resolve(false);
	});

export const withdrawStakedNFT = () =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const nftContract = new web3.eth.Contract(getNFTStakingCodes().abi, nftStakingAddress);
		const accounts = await web3.eth.getAccounts();

		try {
			// check if already staked
			await nftContract.methods.withdrawNFT(0).send({
				from: accounts[0],
			});

			resolve();
		} catch (e) {
			console.log(e);
			reject(e.message);
		}

		resolve();
	});

export const checkAlredyVested = tokenID =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const nftContract = new web3.eth.Contract(getNFTVestingCodes().abi, nftVestingAddress);

		try {
			// check if already staked
			const vested = await nftContract.methods.tokensVested(tokenID).call();
			if (vested) {
				resolve(true);
			}
		} catch (e) {
			resolve(false);
		}

		resolve(false);
	});

export const checkHolderNFT = () =>
	new Promise(async (resolve, reject) => {
		const nfts = [];
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const nftContract = new web3.eth.Contract(getNFTContractCodes().abi, nftContractAddress);
		const accounts = await web3.eth.getAccounts();

		try {
			const totalCount = await nftContract.methods.balanceOf(accounts[0]).call();
			if (totalCount > 0) {
				for (let i = 0; i < totalCount; i++) {
					const tokenID = await nftContract.methods.tokenOfOwnerByIndex(accounts[0], i).call();
					nfts.push(tokenID);
				}
			}

			resolve(nfts);
		} catch (e) {
			reject(e.message);
		}

		resolve(nfts);
	});

export const markApprovedForAll = () =>
	new Promise(async (resolve, reject) => {
		const web3 = window.web3;

		if (!web3) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		const compiledContract = getNFTContractCodes();

		const contract = new web3.eth.Contract(compiledContract.abi, nftContractAddress);

		const accounts = await web3.eth.getAccounts();

		//check if already approved for all
		try {
			const approved = await contract.methods.isApprovedForAll(accounts[0], nftStakingAddress).call({
				from: accounts[0],
			});

			if (approved) {
				resolve();
				return;
			}
		} catch (e) {
			console.log("error", e);
		}

		try {
			contract.methods
				.setApprovalForAll(nftStakingAddress, true)
				.send({ from: accounts[0] })
				.once("receipt", receipt => {
					console.log(receipt);

					resolve();
				})
				.catch(e => {
					reject(e);
				});
		} catch (e) {
			reject(e);
		}
	});
