import { useContext, useEffect, useRef, useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Countdown from "src/componets/CountdownTimer";
import Loading from "src/componets/Loading";
import { formatNumber } from "src/helpers/numeric.helper";
import { truncate } from "src/helpers/string.helper";
import {
	checkAlreadyStaked,
	markApprovedForAll,
	checkHolderNFT,
	stakeNFT,
	createNFTVesting,
	getAllStakes,
} from "src/plugins/Ethereum";
import { switchNetwork } from "src/plugins/Web3Connection";
import { BlockchainContext } from "src/providers/BlockchainProvider";

// 1667260800 start
// 2592000  duration
// 86400 slice
// true revocable

const Stake = () => {
	const blockchainInfo = useContext(BlockchainContext);
	const [holdingNFTs, setHoldingNFTs] = useState([]);
	const [stakedNFT, setStakedNFT] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [connectedWallet, setConnectedWallet] = useState(null);

	const navigate = useNavigate();

	useEffect(() => {
		if (
			blockchainInfo.account &&
			blockchainInfo.networkId === 80001 &&
			blockchainInfo.account !== connectedWallet
		) {
			updateHoldingInfo();
			updateStakingInfo();
			setConnectedWallet(blockchainInfo.account);
		}
	}, [blockchainInfo]);

	const connect = () => {
		blockchainInfo.connectToBlockchain();
	};

	const switchChain = () => {
		switchNetwork();
	};

	const updateStakingInfo = () => {
		checkAlreadyStaked()
			.then(success => setStakedNFT(success))
			.catch(() => setStakedNFT(false));
	};

	const updateHoldingInfo = () => {
		setProcessing(true);

		const holdingNFTs = [];

		checkHolderNFT(connectedWallet)
			.then(nfts => {
				nfts.map(nft => {
					holdingNFTs.push({ tokenID: nft, vested: false });
				});
			})
			.finally(() => {
				getAllStakes()
					.then(staked => {
						staked.map(nft => {
							holdingNFTs.push({ tokenID: nft.tokenId, stakingIndex: nft.stakingIndex, staked: true });
						});
					})
					.catch(e => console.log(e))
					.finally(() => {
						setHoldingNFTs(holdingNFTs);
						setProcessing(false);
					});
			});
	};

	const stake = tokenID => {
		setProcessing(true);
		markApprovedForAll()
			.then(() => {
				stakeNFT(tokenID)
					.then(async () => {
						try {
							await createNFTVesting(tokenID);
						} catch (e) {
							alert(e);
						}
					})
					.finally(() => {
						updateHoldingInfo();
						updateStakingInfo();
					});
			})
			.catch(() => {
				setProcessing(false);
			});
	};

	const vest = (tokenID, stakingIndex) => {
		setProcessing(true);
		createNFTVesting(tokenID).finally(() => {
			updateHoldingInfo();
			updateStakingInfo();
		});
	};

	const ListNFTToStake = () => {
		return (
			<>
				{holdingNFTs.map((item, index) => (
					<Row key={index} style={{ alignItems: "center", margin: "20px 0" }}>
						<Col>Token ID {item.tokenID}</Col>
						<Col>
							{item.staked ? (
								<Button
									variant="primary"
									size="lg"
									disabled={item.vested}
									onClick={() => vest(item.tokenID, item.stakingIndex)}
								>
									Stake
								</Button>
							) : (
								<Button
									variant="primary"
									size="lg"
									disabled={item.vested}
									onClick={() => stake(item.tokenID)}
								>
									Stake
								</Button>
							)}
							{item.vested && (
								<Button variant="primary" size="lg" onClick={() => stake(item.tokenID)}>
									Claim
								</Button>
							)}
						</Col>
					</Row>
				))}
			</>
		);
	};

	const goToClaim = () => {
		navigate("/nft-claim");
	};

	return (
		<>
			<div className="px-4 py-5 mt-5 text-center">
				<h1 className="display-5 fw-bold">Stake your NFT</h1>
				{holdingNFTs?.length <= 0 && <p>Please make sure you hold the NFT with connected wallet</p>}
				{blockchainInfo.account && <p>Connected with {truncate(blockchainInfo.account, 11)}</p>}
			</div>

			<div className="claim-informations_claimable">
				{processing ? (
					<Loading />
				) : (
					<div className={`claim_claimContainer`}>
						{blockchainInfo.account && holdingNFTs?.length <= 0 && (
							<p style={{ textAlign: "center" }}>No NFT found in this wallet.</p>
						)}
						{holdingNFTs?.length > 0 && <ListNFTToStake />}
						{stakedNFT && (
							<div style={{ marginTop: "50px" }}>
								<Button variant="primary" size="lg" onClick={goToClaim}>
									Claim your Reward Tokens
								</Button>
							</div>
						)}
					</div>
				)}
			</div>
			{!blockchainInfo.account && (
				<div className="connect_button">
					<Button variant="primary" size="lg" onClick={connect}>
						Connect your Wallet!
					</Button>
				</div>
			)}
			{blockchainInfo.account && blockchainInfo.networkId !== 80001 && (
				<div className="connect_button">
					<Button variant="primary" size="lg" onClick={switchChain}>
						Switch to Mumbai Testnet (Polygon)
					</Button>
				</div>
			)}
		</>
	);
};
export default Stake;
