import { useContext, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import Countdown from "src/componets/CountdownTimer";
import Loading from "src/componets/Loading";
import { formatNumber } from "src/helpers/numeric.helper";
import {
	getVestingScheduleForWallet,
	getAvailableToClaimForWallet,
	claimTokens,
	tokenContractAddress,
	checkIfNFTAvailableToWithdraw,
	withdrawStakedNFT,
} from "src/plugins/Ethereum";
import { switchNetwork } from "src/plugins/Web3Connection";
import { BlockchainContext } from "src/providers/BlockchainProvider";

// 1667260800 start
// 2592000  duration
// 86400 slice
// true revocable

const NFTClaim = () => {
	const blockchainInfo = useContext(BlockchainContext);
	const [vestingSchedule, setVestingSchedule] = useState(null);
	const [availableToClaim, setAvailableToClaim] = useState(0);
	const [nftAvailableToWithdraw, setNftAvailableToWithdraw] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [connectedWallet, setConnectedWallet] = useState(null);

	const vestingScheduleID = useRef(null);

	useEffect(() => {
		if (
			blockchainInfo.account &&
			blockchainInfo.networkId === 80001 &&
			(!vestingSchedule || blockchainInfo.account !== connectedWallet)
		) {
			updateVestingInfo();
			updateClaimNFTInfo();
			setConnectedWallet(blockchainInfo.account);
		}
	}, [blockchainInfo]);

	const connect = () => {
		blockchainInfo.connectToBlockchain();
	};

	const switchChain = () => {
		switchNetwork();
	};

	const claim = () => {
		setProcessing(true);
		claimTokens(vestingScheduleID.current, availableToClaim, "nft").finally(() => {
			updateVestingInfo();
			setProcessing(false);
		});
	};

	const updateVestingInfo = () => {
		getVestingScheduleForWallet("nft").then(response => setVestingSchedule(response));
		getAvailableToClaimForWallet("nft").then(({ vestingScheduleID: scheduleID, availableToClaim }) => {
			if (availableToClaim) setAvailableToClaim(availableToClaim);
			if (scheduleID) vestingScheduleID.current = scheduleID;
		});
	};

	const updateClaimNFTInfo = () => {
		checkIfNFTAvailableToWithdraw().then(avaialble => {
			if (avaialble) setNftAvailableToWithdraw(true);
		});
	};

	const withdrawNFT = () => {
		setProcessing(true);
		withdrawStakedNFT()
			.then(() => {
				setNftAvailableToWithdraw(false);
			})
			.finally(() => {
				setProcessing(false);
			});
	};

	const addCoin = async () => {
		try {
			// wasAdded is a boolean. Like any RPC method, an error may be thrown.
			const wasAdded = await window.ethereum.request({
				method: "wallet_watchAsset",
				params: {
					type: "ERC20", // Initially only supports ERC20, but eventually more!
					options: {
						address: tokenContractAddress, // The address that the token is at.
						symbol: "TXT", // A ticker symbol or shorthand, up to 5 chars.
						decimals: 0, // The number of decimals in the token
						image: "", // A string url of the token logo
					},
				},
			});

			if (wasAdded) {
				console.log("Thanks for your interest!");
			} else {
				console.log("Your loss!");
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			<div className="px-4 py-5 text-center">
				<h1 className="display-5 fw-bold">Claim your Tokens</h1>
				<p>Please make sure you have a minimum balance of 0.01 MATIC to withdraw</p>
				{vestingSchedule?.totalTokens > 0 &&
					vestingSchedule?.nextClaimTime &&
					availableToClaim <= 0 &&
					vestingSchedule.totalTokens - vestingSchedule.alreadyClaimed > 0 && (
						<>
							<div className="my-3 text-center">Next Claim in</div>
							<div className="my-3 browse-time-left">
								<Countdown date={vestingSchedule.nextClaimTime * 1000} />
							</div>
						</>
					)}
			</div>

			<div
				className={`claim_claimContainer ${
					!blockchainInfo.account || blockchainInfo.networkId !== 80001 ? "disabled" : ""
				}`}
			>
				<div className="content_content claim-informations_content">
					<div className="content_inner claim-informations_contentInner">
						<p className="text_default text_secondary claim-informations_text">Your Total Tokens</p>
						<p className="text_default claim-informations_value">
							<div className="price_container">
								{vestingSchedule ? formatNumber(vestingSchedule.totalTokens) : 0}
							</div>
						</p>
					</div>
				</div>
				<div className="content_content claim-informations_content">
					<div className="content_inner claim-informations_contentInner">
						<p className="text_default text_secondary claim-informations_text">Already claimed</p>
						<p className="text_default claim-informations_value">
							<div className="price_container">
								{vestingSchedule ? formatNumber(vestingSchedule.alreadyClaimed) : 0}
							</div>
						</p>
					</div>
				</div>
				<div className="content_content claim-informations_content">
					<div className="content_inner claim-informations_contentInner">
						<p className="text_default text_secondary claim-informations_text">Balance to Claim</p>
						<p className="text_default claim-informations_value">
							<div className="price_container">
								{vestingSchedule
									? formatNumber(vestingSchedule.totalTokens - vestingSchedule.alreadyClaimed)
									: 0}
							</div>
						</p>
					</div>
				</div>
				<div className="claim-informations_dateContainer">
					<div className="content_content claim-informations_content">
						<div className="content_inner claim-informations_contentInner">
							<p className="text_default text_secondary claim-informations_text">Start date</p>
							<p className="text_default claim-informations_value">
								{vestingSchedule?.startTime
									? new Date(vestingSchedule.startTime * 1000).toDateString()
									: "-"}
							</p>
						</div>
					</div>
					<div className="content_content claim-informations_content">
						<div className="content_inner claim-informations_contentInner">
							<p className="text_default text_secondary claim-informations_text">Finish date</p>
							<p className="text_default claim-informations_value">
								{vestingSchedule?.endTime
									? new Date(vestingSchedule.endTime * 1000).toDateString()
									: "-"}
							</p>
						</div>
					</div>
				</div>
				<div className="claim-informations_claimable">
					<div className="title_default_ title_h1 my-3">
						AVAILABLE TO CLAIM NOW
						<div className="price_container" style={{ marginTop: "15px" }}>
							{availableToClaim} Tokens
						</div>
					</div>
					{processing ? (
						<Loading />
					) : availableToClaim > 0 ? (
						<Button variant="danger" size="lg" disabled={availableToClaim <= 0} onClick={claim}>
							CLAIM YOUR TOKENS
						</Button>
					) : null}
					{nftAvailableToWithdraw && (
						<div className="connect_button" style={{ marginTop: "50px" }}>
							<Button variant="primary" size="lg" onClick={withdrawNFT}>
								Withdraw your NFT
							</Button>
						</div>
					)}
					<p className="my-3" style={{ fontSize: "14px" }}>
						<Button variant="link" onClick={addCoin}>
							Add Coin to your Wallet!
						</Button>
					</p>
				</div>
			</div>
			{!blockchainInfo.account && (
				<div className="connect_button">
					<Button variant="primary" size="lg" onClick={connect}>
						Connect your Wallet!
					</Button>
				</div>
			)}
			{blockchainInfo.account && blockchainInfo.networkId !== 80001 && (
				<div className="connect_button">
					<Button variant="primary" size="lg" onClick={switchChain}>
						Switch to Mumbai Testnet (Polygon)
					</Button>
				</div>
			)}
		</>
	);
};
export default NFTClaim;
