export function truncate(fullStr, strLen, separator) {
	if (fullStr.length <= strLen) return fullStr;

	separator = separator || "...";

	var sepLen = separator.length,
		charsToShow = strLen - sepLen,
		frontChars = Math.ceil(charsToShow / 2),
		backChars = Math.floor(charsToShow / 2);

	return (
		"0x" + fullStr.replace("0x", "").substr(0, frontChars) + separator + fullStr.substr(fullStr.length - backChars)
	);
}
