import Frontend from "./layouts/Frontend";
import Home from "./pages/Home";
import NFTClaim from "./pages/NftClaim";
import Stake from "./pages/Stake";

const routes = [
	{
		path: "/",
		element: <Frontend />,
        children: [
            {
                path: "/",
                element: <Home />
            },
            {
                path: "/stake",
                element: <Stake />
            },
            {
                path: "/nft-claim",
                element: <NFTClaim />
            }
        ]
	},
];

export default routes;
