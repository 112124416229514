import { useRoutes } from "react-router-dom";
import BlockchainProvider from "src/providers/BlockchainProvider";
import logo from "./logo.svg";
import routes from "src/routes";
import "./App.css";

function App() {
	let element = useRoutes(routes);

	return (
		<BlockchainProvider>
			<div className="App">{element}</div>
		</BlockchainProvider>
	);
}

export default App;
