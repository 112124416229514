import * as React from "react";

const timeDeltaFormatOptionsDefaults = {
	daysInHours: false,
	zeroPadTime: 2,
};

const CountdownStatus = {
	STARTED: "STARTED",
	PAUSED: "PAUSED",
	STOPPED: "STOPPED",
	COMPLETED: "COMPLETED",
};

function zeroPad(value, length) {
	const strValue = String(value);
	if (length === 0) return strValue;
	const match = strValue.match(/(.*?)([0-9]+)(.*)/);
	const prefix = match ? match[1] : "";
	const suffix = match ? match[3] : "";
	const strNo = match ? match[2] : strValue;
	const paddedNo =
		strNo.length >= length ? strNo : ([...Array(length)].map(() => "0").join("") + strNo).slice(length * -1);
	return `${prefix}${paddedNo}${suffix}`;
}

function calcTimeDelta(date, options) {
	const { now = Date.now, precision = 0, controlled, offsetTime = 0, overtime } = options;
	let startTimestamp;

	if (typeof date === "string") {
		startTimestamp = new Date(date).getTime();
	} else if (date instanceof Date) {
		startTimestamp = date.getTime();
	} else {
		startTimestamp = date;
	}

	if (!controlled) {
		startTimestamp += offsetTime;
	}

	const timeLeft = controlled ? startTimestamp : startTimestamp - now();
	const clampedPrecision = Math.min(20, Math.max(0, precision));
	const total = Math.round(
		parseFloat(((overtime ? timeLeft : Math.max(0, timeLeft)) / 1000).toFixed(clampedPrecision)) * 1000
	);

	const seconds = Math.abs(total) / 1000;

	return {
		total,
		days: Math.floor(seconds / (3600 * 24)),
		hours: Math.floor((seconds / 3600) % 24),
		minutes: Math.floor((seconds / 60) % 60),
		seconds: Math.floor(seconds % 60),
		milliseconds: Number(((seconds % 1) * 1000).toFixed()),
		completed: total <= 0,
	};
}

function formatTimeDelta(timeDelta, options) {
	const { days, hours, minutes, seconds } = timeDelta;
	const {
		daysInHours,
		zeroPadTime,
		zeroPadDays = zeroPadTime,
	} = {
		...timeDeltaFormatOptionsDefaults,
		...options,
	};

	const zeroPadTimeLength = Math.min(2, zeroPadTime);
	const formattedHours = daysInHours ? zeroPad(hours + days * 24, zeroPadTime) : zeroPad(hours, zeroPadTimeLength);

	return {
		days: daysInHours ? "" : zeroPad(days, zeroPadDays),
		hours: formattedHours,
		minutes: zeroPad(minutes, zeroPadTimeLength),
		seconds: zeroPad(seconds, zeroPadTimeLength),
	};
}

export default class Countdown extends React.Component {
	static defaultProps = {
		...timeDeltaFormatOptionsDefaults,
		controlled: false,
		intervalDelay: 1000,
		precision: 0,
		autoStart: true,
	};

	mounted = false;
	interval;
	api;

	initialTimestamp = this.calcOffsetStartTimestamp();
	offsetStartTimestamp = this.props.autoStart ? 0 : this.initialTimestamp;
	offsetTime = 0;

	constructor(props) {
		super(props);

		if (props.date) {
			const timeDelta = this.calcTimeDelta();
			this.state = {
				timeDelta,
				status: timeDelta.completed ? CountdownStatus.COMPLETED : CountdownStatus.STOPPED,
			};
		}
	}

	componentDidMount() {
		this.mounted = true;
		if (this.props.onMount) this.props.onMount(this.calcTimeDelta());
		if (this.props.autoStart) this.start();
	}

	componentDidUpdate(prevProps) {
		if (this.props.date !== prevProps.date) {
			this.initialTimestamp = this.calcOffsetStartTimestamp();
			this.offsetStartTimestamp = this.initialTimestamp;
			this.offsetTime = 0;

			this.setTimeDeltaState(this.calcTimeDelta());
		}
	}

	componentWillUnmount() {
		this.mounted = false;
		this.clearTimer();
	}

	tick = () => {
		const timeDelta = this.calcTimeDelta();
		const callback = timeDelta.completed && !this.props.overtime ? undefined : this.props.onTick;
		this.setTimeDeltaState(timeDelta, undefined, callback);
	};

	calcTimeDelta() {
		const { date, now, precision, controlled, overtime } = this.props;
		return calcTimeDelta(date, {
			now,
			precision,
			controlled,
			offsetTime: this.offsetTime,
			overtime,
		});
	}

	calcOffsetStartTimestamp() {
		return Date.now();
	}

	start = () => {
		if (this.isStarted()) return;

		const prevOffsetStartTimestamp = this.offsetStartTimestamp;
		this.offsetStartTimestamp = 0;
		this.offsetTime += prevOffsetStartTimestamp ? this.calcOffsetStartTimestamp() - prevOffsetStartTimestamp : 0;

		const timeDelta = this.calcTimeDelta();
		this.setTimeDeltaState(timeDelta, CountdownStatus.STARTED, this.props.onStart);

		if (!this.props.controlled && (!timeDelta.completed || this.props.overtime)) {
			this.clearTimer();
			this.interval = window.setInterval(this.tick, this.props.intervalDelay);
		}
	};

	pause = () => {
		if (this.isPaused()) return;

		this.clearTimer();
		this.offsetStartTimestamp = this.calcOffsetStartTimestamp();
		this.setTimeDeltaState(this.state.timeDelta, CountdownStatus.PAUSED, this.props.onPause);
	};

	stop = () => {
		if (this.isStopped()) return;

		this.clearTimer();
		this.offsetStartTimestamp = this.calcOffsetStartTimestamp();
		this.offsetTime = this.offsetStartTimestamp - this.initialTimestamp;
		this.setTimeDeltaState(this.calcTimeDelta(), CountdownStatus.STOPPED, this.props.onStop);
	};

	clearTimer() {
		window.clearInterval(this.interval);
	}

	isStarted = () => {
		return this.isStatus(CountdownStatus.STARTED);
	};

	isPaused = () => {
		return this.isStatus(CountdownStatus.PAUSED);
	};

	isStopped = () => {
		return this.isStatus(CountdownStatus.STOPPED);
	};

	isCompleted = () => {
		return this.isStatus(CountdownStatus.COMPLETED);
	};

	isStatus(status) {
		return this.state.status === status;
	}

	handleOnComplete = timeDelta => {
		if (this.props.onComplete) this.props.onComplete(timeDelta);
	};

	setTimeDeltaState(timeDelta, status, callback) {
		if (!this.mounted) return;

		let completedCallback;

		if (!this.state.timeDelta.completed && timeDelta.completed) {
			if (!this.props.overtime) this.clearTimer();
			completedCallback = this.handleOnComplete;
		}

		const onDone = () => {
			if (callback) callback(this.state.timeDelta);
			if (completedCallback) completedCallback(this.state.timeDelta);
		};

		return this.setState(prevState => {
			let newStatus = status || prevState.status;

			if (timeDelta.completed && !this.props.overtime) {
				newStatus = CountdownStatus.COMPLETED;
			} else if (!status && newStatus === CountdownStatus.COMPLETED) {
				newStatus = CountdownStatus.STOPPED;
			}

			return {
				timeDelta,
				status: newStatus,
			};
		}, onDone);
	}

	getApi() {
		return (this.api = this.api || {
			start: this.start,
			pause: this.pause,
			stop: this.stop,
			isStarted: this.isStarted,
			isPaused: this.isPaused,
			isStopped: this.isStopped,
			isCompleted: this.isCompleted,
		});
	}

	getRenderProps() {
		const { daysInHours, zeroPadTime, zeroPadDays } = this.props;
		const { timeDelta } = this.state;
		return {
			...timeDelta,
			api: this.getApi(),
			props: this.props,
			formatted: formatTimeDelta(timeDelta, {
				daysInHours,
				zeroPadTime,
				zeroPadDays,
			}),
		};
	}

	render() {
		const { overtime, children, renderer } = this.props;
		const renderProps = this.getRenderProps();

		if (renderer) {
			return renderer(renderProps);
		}

		if (children && this.state.timeDelta.completed && !overtime) {
			return React.cloneElement(children, { countdown: renderProps });
		}

		const { days, hours, minutes, seconds } = renderProps.formatted;
		return (
			<ul className="clearfix text-center">
				{days && (
					<li>
						<span>{days}</span> Days
					</li>
				)}
				{hours && (
					<li>
						<span>{hours}</span> Hour
					</li>
				)}
				<li>
					<span>{minutes}</span> Min
				</li>
				<li>
					<span>{seconds}</span> Sec
				</li>
			</ul>
		);
	}
}
